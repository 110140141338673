export enum QuotaKeys {
  SERVERLESS_STORAGE_GB_PER_PROJECT = 'serverless_storage_gb_per_project',
  READ_UNITS_PER_MONTH = 'read_units_per_month',
  WRITE_UNITS_PER_MONTH = 'write_units_per_month',
  SERVERLESS_INDEXES_PER_PROJECT = 'serverless_indexes_per_project',
  PODS_PER_PROJECT = 'pods_per_project',
}

export type QuotaLimit = {
  id: string;
  key: QuotaKeys;
  type: string;
  scope: string;
  value: number;
  is_override: boolean;
};
