import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput, {type OutlinedInputProps} from '@mui/material/OutlinedInput';
import {type Ref} from 'react';
import {type Control, Controller, type Path} from 'react-hook-form';
import IconButton from '../Buttons/IconButton';
import InputWrapper from './Utils/InputWrapper';
import {inputStyles} from './Utils/styles';
import {type InputProps} from './Utils/types';

interface TextInputSpecificProps {
  multiline?: boolean;
  rows?: number;
  maxRows?: number;
  searchIcon?: boolean;
  clearable?: boolean;
  hideError?: boolean;
  inputRef?: Ref<HTMLInputElement>;
}

interface UnControlledTextInputProps extends InputProps, TextInputSpecificProps {
  value: string;
  onChange: (value: string) => void;
}

interface ControlledTextInputProps<T extends Record<string, any>>
  extends InputProps,
    TextInputSpecificProps {
  name: Path<T>;
  control: Control<T>;
  validate?: (data: T) => boolean;
}

type TextInputProps<T extends Record<string, any>> =
  | ControlledTextInputProps<T>
  | UnControlledTextInputProps;

function startAdornment({
  searchIcon,
  adornment,
}: {
  searchIcon?: boolean;
  adornment?: React.ReactNode;
}) {
  if (searchIcon || adornment) {
    return (
      <InputAdornment position="start">
        {searchIcon && <SearchIcon />}
        {adornment}
      </InputAdornment>
    );
  }
  return undefined;
}

function endAdornment({
  clearable,
  adornment,
  clearValue,
  valueLength,
}: {
  clearable?: boolean;
  adornment?: React.ReactNode;
  clearValue?: () => void;
  valueLength: number;
}) {
  if (adornment || clearable) {
    return (
      <InputAdornment position="end">
        {adornment}
        {clearable && valueLength > 0 && (
          <IconButton
            id="clear-input"
            shape={'circle' as const}
            aria-label="clear-input"
            onClick={clearValue}
            icon={<CloseIcon color="secondary" />}
            sx={{m: 0}}
          />
        )}
      </InputAdornment>
    );
  }
  return undefined;
}

function TextInput<T extends Record<string, any>>(props: TextInputProps<T>) {
  const textFormatProps: OutlinedInputProps = {
    id: props.id,
    name: props.name,
    size: 'small',
    margin: 'dense',
    color: props.color || 'primary',
    placeholder: props.placeholder,
    required: props.required,
    disabled: props.disabled,
    autoFocus: props.autoFocus,
    multiline: props.multiline,
    rows: props.rows,
    maxRows: props.maxRows,
    startAdornment: startAdornment({searchIcon: props.searchIcon, adornment: props.startAdornment}),
    endAdornment: endAdornment({
      clearable: props.clearable,
      adornment: props.endAdornment,
      clearValue: 'control' in props ? undefined : () => props.onChange(''),
      valueLength: 'control' in props ? 0 : props.value?.length || 0,
    }),
    fullWidth: props.fullWidth,
    'aria-placeholder': props.placeholder,
    sx: {...inputStyles.input, ...props.sx},
    inputProps: {
      'data-testid': props.id || props.name || props.label?.toLowerCase(),
      'aria-label': props.label?.toLowerCase(),
      readOnly: props.readOnly,
      sx: props.multiline ? {py: 1} : {},
    },
    ...props.outlineInputProps,
  };

  if ('control' in props) {
    return (
      <Controller
        name={props.name}
        control={props.control}
        rules={{validate: props.validate}}
        render={({field, fieldState: {error}}) => (
          <InputWrapper {...props} error={props.error || error?.message}>
            <OutlinedInput
              inputRef={props.inputRef}
              value={field.value}
              onChange={(event) => field.onChange(event.target.value)}
              endAdornment={endAdornment({
                clearable: props.clearable,
                adornment: props.endAdornment,
                clearValue: () => field.onChange(''),
                valueLength: field.value?.length || 0,
              })}
              error={!!(props.error || error?.message)}
              {...textFormatProps}
            />
          </InputWrapper>
        )}
      />
    );
  }
  return (
    <InputWrapper {...props}>
      <OutlinedInput
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
        error={!!props.error}
        {...textFormatProps}
        inputRef={props.inputRef}
      />
    </InputWrapper>
  );
}

export default TextInput;
